import { Image } from '@mantine/core'

import Header from '../DriverApplicationPage/components/Header'

const ForbiddenPage = () => (
  <div className="flex h-screen flex-col">
    <Header />
    <main className="flex-grow overflow-auto">
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <Image
            radius="md"
            h={450}
            w="auto"
            fit="contain"
            src="/images/forbidden-opt-removebg.png"
            alt="truck404"
          />
          <p className="mt-4 text-gray-600">
            {
              "Oops! It seems you don't have access to this page. Please re-authenticate to continue."
            }
          </p>
        </div>
      </div>
    </main>
  </div>
)

export default ForbiddenPage
